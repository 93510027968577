import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import Page from '../../../components/page.vue'
import NoData from '../../../components/NoData.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'NewsList',
	data(){
		return{
			newsList:[],//新闻列表
			pageIndex:1,
			pageSize:10,
			hotNewsList:[],//热门新闻
			PageCount:0,
			HotDynamicList:[]
		}
	},
	methods:{
		GetNewsList(){ //获取新闻列表
			var that = this
			CheckInService.GetNewsList(that.pageIndex,that.pageSize).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.newsList = res.Result
					that.PageCount = res.TotalCount
				}
			})
		},
		pageChange(page){ //切换分页
			this.pageIndex = page
			this.GetNewsList()
			window.scrollTo(0,0)
		},
		GetHotNewsList(){ //获取热门新闻
			var that = this
			CheckInService.GetNewsHotList(5).then(res=>{
				if(res.Tag == 1){
					that.hotNewsList = res.Result
				}
			})
		},
		GetHotDynamicList(){ //获取热门动态
			var that = this
			CheckInService.GetHotAcademicDynamicList(5).then(res=>{
				if(res.Tag == 1){
					that.HotDynamicList = res.Result
				}
			})
		},
		
	},
	created(){
		this.GetNewsList()
		this.GetHotNewsList()
		this.GetHotDynamicList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'Page':Page,
		'NoData':NoData
	}
}